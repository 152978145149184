import { useContext, useEffect, useMemo } from "react";
import { NextPage } from "next";

// Components
import Header from "components/Header";
import Footer from "components/Footer";

// Providers
import { PageContext, PageProvider } from "providers/PageProvider";

// Utils
import { prepareNavData } from "utils/common";
import { trackEvent } from "@utils/firebase";

// Hooks
import Head from "next/head";
import NotFound from "@components/NotFound/NotFound";

const Home = () => {
  const { page } = useContext(PageContext);

  const { navData, networks } = useMemo(
    () => prepareNavData(page.data.navData, undefined),
    [page.data.navData]
  );
  const pageData = page.data.pageData;
  const bannerData = pageData.banner;

  useEffect(() => {
    const navItems = pageData.sections.map(
      (section: any) =>
        section?.section_details?.section_id ||
        section?.sectionDetails?.section_id
    );

    const mainElements = navItems.map((id: string) =>
      document.getElementById(id)
    );

    const observer = new IntersectionObserver(
      (entries) => {
        for (let entry of entries) {
          if (entry.isIntersecting) {
            trackEvent(`VIEW_${entry.target.id}`);
          }
        }
      },
      {
        threshold: 0.5,
      }
    );

    mainElements.forEach(
      (element: Element) => element && observer.observe(element)
    );

    // cleanup function
    return () => {
      mainElements.forEach(
        (element: Element) => element && observer.unobserve(element)
      );
    };
  }, [pageData.sections]);

  return (
    <>
      <Head>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Header
        data={navData}
        networks={networks}
        banner={bannerData}
        tvl={undefined}
      />
      <NotFound />
      <Footer data={navData} isEmailModalRequired={false} />
    </>
  );
};

const HomePage: NextPage = ({ page }: any) => {
  return (
    <PageProvider page={page}>
      <Home />
    </PageProvider>
  );
};

export default HomePage;
