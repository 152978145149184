import { Box, Flex, Text, Image } from "@chakra-ui/react";

const NotFound = () => {
  return (
    <Flex
      flexDirection={{ base: "column-reverse", lg: "row" }}
      alignItems="center"
      justifyContent="space-between"
      w="100%"
      maxW="1192px"
      py={{ base: "64px", lg: "120px" }}
    >
      <Box p={{ base: "24px 16px 40px 16px", lg: 0 }}>
        <Text
          fontSize={{ base: "32px", lg: "64px" }}
          fontWeight="700"
          lineHeight={{ base: "38px", lg: "120%" }}
          color="textPrimary"
          textAlign={{ base: "center", lg: "left" }}
        >
          Page Not Found
        </Text>
        <Text
          mt="12px"
          fontSize={{ base: "12px", lg: "20px" }}
          fontWeight={{ base: "500", lg: "600" }}
          lineHeight={{ base: "18px", lg: "24px" }}
          color="textSecondary"
          textAlign={{ base: "center", lg: "left" }}
        >
          {"We're sorry, but the page you are looking for could not be found."}
        </Text>
      </Box>
      <Box>
        <Image
          src="/assets/images/404.svg"
          alt="notfound"
          boxSize={{ base: "240px", lg: "480px" }}
        />
      </Box>
    </Flex>
  );
};

export default NotFound;
